
import { Vue, Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { phoneAndFixedLine, email } from '@/utils/validate'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { FetchSuggestionsCallback } from 'element-ui/types/autocomplete'
import { SupplierDataList } from '@/types/material'
@Component({
  name: 'SuppliermanageAdd'
})
export default class extends Vue {
  private typeList = []
  private formInfo: SupplierDataList = {
    supplierId: '',
    supplierName: '',
    supplierCode: '',
    supplierType: '',
    supplierTypeName: '',
    // 经营范围
    operationScope: '',
    // 供应商介绍
    supplierDesc: '',
    // 供应商级别
    supplierLevel: '',
    // 供应商邮编
    supplierPostal: '',
    // 省
    province: '',
    city: '',
    area: '',
    // 详细地址
    address: '',
    contactName: '',
    contactMobile: ''
  }

  private rulesForm = {
    supplierName: [
      { required: true, message: '请输入供应商名称', trigger: 'blur' }
    ],
    supplierType: [
      { required: true, message: '请输入供应商类型', trigger: 'change' }
    ],
    contactName: [
      { required: true, message: '请输入联系人姓名', trigger: 'change' }
    ],
    contactMobile: [
      { required: true, message: '请输入联系人电话', trigger: 'blur' },
      { validator: phoneAndFixedLine, trigger: ['change', 'blur'] }
    ],
    mail: [
      { validator: email, trigger: ['change', 'blur'] }
    ]
  }

  private loading = false
  private addrList = [] // 地址数据json
  private addrInfo: Array<string> = [] // 省市区完整数据

  get supplierId () {
    return this.$route.query.supplierId || ''
  }

  created () {
    this.getConfigList()
    this.getAddrList()
    this.supplierId && this.getDetail()
  }

  // 获取供应商类型
  getConfigList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'supplierType' }).then(res => {
      this.typeList = res.supplierType || []
    })
  }

  // 获取地址信息
  getAddrList () {
    this.$axios.get(this.$apis.common.getRegion).then(res => {
      this.addrList = res || []
    })
  }

  areaChange () {
    if (this.addrInfo.length === 3 && this.addrInfo[2] !== '') {
      this.formInfo.province = this.addrInfo[0] || ''
      this.formInfo.city = this.addrInfo[1] || ''
      this.formInfo.area = this.addrInfo[2] || ''
    } else {
      this.formInfo.province = ''
      this.formInfo.city = ''
      this.formInfo.area = ''
    }
  }

  // 详细地址输入
  addrFetch (queryString: string, cb: FetchSuggestionsCallback) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.AutoComplete',
        'AMap.Geocoder'
      ]
    }).then(() => {
      // 实例化Autocomplete
      const autoComplete = new AMap.Autocomplete({
        city: this.formInfo.city
      })
      autoComplete.search(this.formInfo.area + queryString, (status, result) => {
        const res = result as AMap.Autocomplete.SearchResult
        if (status === 'complete') {
          cb(res.tips)
        }
      })
    })
  }

  // 点击详细地址下拉
  addrChange (data: AMap.Autocomplete.Tip) {
    this.formInfo.address = data.name
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.material.selectYhSupplierBySupplierId, {
      supplierId: this.supplierId
    }).then((res) => {
      this.formInfo = res || {}
      this.addrInfo = [res.province, res.city, res.area]
    }).finally(() => {
      this.loading = false
    })
  }

  onSubmit () {
    (this.$refs.formInfo as ElForm).validate((valid) => {
      if (valid) {
        const url = this.supplierId ? this.$apis.material.updateYhSupplier : this.$apis.material.insertYhSupplier
        // 编辑去除字段
        delete this.formInfo.supplierTypeName
        this.$axios.post(url, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.$router.back()
        })
      }
    })
  }
}
